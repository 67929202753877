window.addEventListener('DOMContentLoaded', function () {
	/**
	 * スローガン以外の周りの画像表示間隔。単位ミリ秒
	 */
	const duration = 1000 * 1.2;

	/**
	 * ホームのメインバナーを制御する
	 * 
	 * @param NodeList imagesBanner 
	 * @param NodeList imagesOther 
	 * @param Node imageSlogan 
	 * @param integer duration 
	 */
	const HomeMainBanners = function (imagesBanner, imagesOther, imageSlogan, duration) {
		this.imagesBanner = imagesBanner
		this.imagesOther = imagesOther
		this.imageSlogan = imageSlogan
		this.duration = duration
		this.loaded = []

		this.execute = function () {
			const self = this
			this.imagesBanner.forEach(function (banner) {
				// domロード終了時では、画像本体のロード完了は保障されていない。
				// そのため、ファイルキャッシュがあるときは問題ないが、初回アクセス時は動作が安定しない。
				// 初回時の動作を安定にするため、いったんimgのsrc値を空値にし、
				// img参照先の画像本体がダウンロードされた時点で制御実行させる。
				const src = banner.getAttribute('src');
				banner.setAttribute('src', '');
				banner.addEventListener('load', function () {
					self.loaded.push(true)
		
					if (self.loaded.length > 1) {
						// アニメーション処理の内訳はスタイルに依存している。
						// themes/css/top.scssを参照
						self.imageSlogan.classList.add('active')
		
						setTimeout(function () {
							self.imagesOther.forEach(function (image) {
								image.classList.add('start');
							})
						}, self.duration)
					}
				});
				banner.setAttribute('src', src)
			});
		
			return this
		}
	};

	(new HomeMainBanners(
		document.querySelectorAll('#mv h2 img'),
		document.querySelectorAll('#mv .img'),
		document.querySelector('#mv img[data-role="slogan"]'),
		duration
	)).execute()
})